<a class="article-card" [ngClass]="'article-card--' + size()" [href]="article()?.linkTo">
  <div class="article-card__img-frame eb-img-frame eb-img-frame--with-desc" [ngClass]="{'eb-skeleton': skeleton()}">
    <img *ngIf="article() && !skeleton()" class="article-card__img w-full h-full" [src]="article()!.imageUrl" [alt]="article()!.title">
  </div>
  <div class="article-card__desc" [ngClass]="{ 'article-card__desc--overlay': size() === 'extra-large'}">
    <div *ngIf="size() !== 'extra-large'; else extraLargeDescription" class="article-card__category flex">
      <ng-template *ngFor="let tag of article()?.tags ?? [] | slice:0:amountOfTagsVisible()" [ngTemplateOutlet]="articleTag" [ngTemplateOutletContext]="{tag: tag, skeleton: skeleton()}"/>
      <p class="eb-size--tiny mb-0 flex align-items-center mt-1 md:mt-0 ml-auto">
        <eb-icon *ngIf="!skeleton()" class="text-base" key="calendar" />
        <span class="ml-1"  [ngClass]="{'eb-skeleton': skeleton()}" [style]="skeleton() ? 'width: 80px' : ''">{{article()?.date | ebDate}}</span>
      </p>

    </div>

    <ng-template #extraLargeDescription>
      <ng-container *ngIf="!skeleton()">
        <div>
          <p class="eb-size--tiny mb-0 flex align-items-center mt-1 md:mt-0">
            <eb-icon class="text-base article-card__date-icon" key="calendar" />
            <span class="ml-1 article-card__date">{{article()?.date | ebDate}}</span>
          </p>
          <div class="article-card__tags--extra-large">
            <ng-template *ngFor="let tag of article()?.tags ?? [] | slice:0:amountOfTagsVisible()" [ngTemplateOutlet]="articleTag" [ngTemplateOutletContext]="{tag: tag, skeleton: skeleton()}"/>
          </div>
        </div>
      </ng-container>
    </ng-template>


    <div><p *ngIf="!skeleton() || size() !== 'extra-large'" class="h6 article-card__title mb-1" [ngClass]="{'eb-skeleton': skeleton()}" >{{article()?.title}}</p></div>
    <p *ngIf="skeleton() && size() !== 'extra-large'" class="mb-1 eb-skeleton"></p>
  </div>
</a>

<ng-template #articleTag let-tag="tag" let-skeleton="skeleton">
  <span class="eb-tag mr-2 text-white font-light px-2" [ngClass]="{'eb-skeleton': skeleton}" [style]="{'background-color': tag?.backgroundColor, 'color': tag?.foregroundColor, 'width': skeleton ? '70px' : 'auto'}">{{tag?.text}}</span>
</ng-template>

import {
  ChangeDetectionStrategy,
  Component, computed,
  ElementRef,
  EventEmitter,
  inject,
  Output,
  Renderer2, Signal,
  signal,
  ViewChild, WritableSignal
} from '@angular/core';

@Component({
    selector: 'eb-scrollbar',
    templateUrl: './scrollbar.component.html',
    styleUrls: ['./scrollbar.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EbScrollbarComponent {
    private readonly _renderer = inject(Renderer2);

    thumbWidth: WritableSignal<number> = signal(100);
    isActive: Signal<boolean> = computed(() => this.thumbWidth() < 100);

    thumbPosition = signal(0);
    scrolled: WritableSignal<boolean> = signal(false);
    startScrollPosition = 0;
    dark: WritableSignal<boolean> = signal(false);
    vertical: WritableSignal<boolean> = signal(false);
    fullWidthMobile: WritableSignal<boolean> = signal(false);

    moveListener: (() => void | undefined) | undefined;
    mouseUpListener: (() => void | undefined) | undefined;

    @Output() readonly startScroll = new EventEmitter();
    @Output() readonly stopScroll = new EventEmitter();
    @Output() readonly moveScroll: EventEmitter<number> = new EventEmitter<number>();
    @Output() readonly scrollTo: EventEmitter<number> = new EventEmitter<number>();

    @ViewChild('scrollbarTrack') readonly scrollbarTrack!: ElementRef;

    protected _startScroll(event: MouseEvent): void {
        this.startScrollPosition = this.vertical() ? event.pageY : event.pageX;
        this.scrolled.set(true);
        if (this.startScroll.observed) {
            this.startScroll.emit();
        }
        this.moveListener = this._renderer.listen('window', 'mousemove', (_event: MouseEvent) =>
            this._moveScroll(_event),
        );
        this.mouseUpListener = this._renderer.listen('window', 'mouseup', () => this._stopScroll());
    }

    private _stopScroll(): void {
        if (this.scrolled() && this.stopScroll.observed) {
            this.stopScroll.emit();
        }

        if (this.moveListener) {
            this.moveListener();
        }

        if (this.mouseUpListener) {
            this.mouseUpListener();
        }

        this.scrolled.set(false);
    }

    private _moveScroll(event: MouseEvent): void {
        if (this.scrolled()) {
            const diff = (this.vertical() ? event.pageY : event.pageX) - this.startScrollPosition;
            this.startScrollPosition = this.vertical() ? event.pageY : event.pageX;
            if (this.moveScroll.observed) {
                this.moveScroll.emit(diff);
            }
        }
    }

    protected onTrackClick(event: MouseEvent): void {
        if (this.scrollTo.observed) {
            this.scrollTo.emit(event.offsetX);
        }
    }
}

import { ChangeDetectionStrategy, Component, input, Input, InputSignal } from '@angular/core';

@Component({
    selector: 'eb-ticket-panel',
    templateUrl: './ticket-panel.component.html',
    styleUrls: ['./ticket-panel.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketPanelComponent {
    noPriceInfo: InputSignal<boolean> = input(false);
}

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, Signal, signal, viewChild, ViewChild } from '@angular/core';
import { UiDrawerModule } from '@e-bilet/ui-drawer';
import { EbCustomDrawerComponent } from 'libs/ui-custom-drawer/src/lib/drawer/custom-drawer.component';
import { EbQuickSearchInputGroupComponent } from '../quick-search-input-group/quick-search-input-group.component';

@Component({
    selector: 'eb-search-drawer',
    templateUrl: './search-drawer.component.html',
    styleUrls: ['./search-drawer.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, UiDrawerModule, EbQuickSearchInputGroupComponent, EbCustomDrawerComponent],
})
export class EbSearchDrawerComponent {
    isVisible: Signal<boolean> = signal(false);

    ebQuickSearchInputGroupComponent: Signal<EbQuickSearchInputGroupComponent> = viewChild.required(
        EbQuickSearchInputGroupComponent,
    );

    constructor() {
        effect(() => {
            this.ebQuickSearchInputGroupComponent().formSubmitted.subscribe(() => this.closeDrawer());
        });
    }

    @ViewChild(EbCustomDrawerComponent) ebCustomDrawerComponent!: EbCustomDrawerComponent;

    protected closeDrawer(): void {
        this.ebCustomDrawerComponent.closeWithNoAnimation();
    }
}

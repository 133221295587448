@if (isVisible()) {
  <div
    class="eb-custom-drawer__mask fixed bottom-0 left-0 w-full bg-black-alpha-40 fadein animation-duration-500"
    [ngClass]="{ 'eb-fade-out': closing(), 'h-full': isFullHeight() }"
    (click)="close()"
  >
    <div
      class="eb-custom-drawer__container absolute bottom-0 w-full bg-white"
      [ngClass]="{ 'h-full': isFullHeight(), 'h-auto': isTitleEventCalendarItem() }"
      (click)="$event.stopPropagation()"
    >
      @if (!isTitleEventCalendarItem()) {
        <div class="eb-custom-drawer__topbar flex justify-content-end align-items-center w-full bg-black-alpha-10">
          <eb-icon class="m-4 cursor-pointer" key="close" (click)="close()" />
        </div>
      }
      <div
        class="eb-custom-drawer__content h-full overflow-auto"
        [ngClass]="{ 'bg-black-alpha-10 overflow-hidden': isGallery(), 'eb-custom-drawer__content--calendar-item': isTitleEventCalendarItem() }"
      >
        <ng-content />
      </div>
    </div>
  </div>
}

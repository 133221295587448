<eb-custom-drawer #drawer>
  <div #container class="main-container eb-category-mega-menu py-2" *transloco="let t">
    <div class="eb-category-mega-menu__actionbar" *ngIf="activeCategory()">
      <button eb-button (click)="activeCategory.set(null); activeSubcategory.set(null)">
        <eb-icon key="chevron-left" />
        <span>{{ activeCategory()?.categoryName }}</span>
      </button>
    </div>
    <div [ngClass]="{ hidden: activeCategory() }">
      <a [routerLink]="[i18nService.isEnglishActive ? '/en' : '/', 'wydarzenia']" (click)="drawer.close()" class="category-tree__option">
        <div>{{ t('shared.category-mega-menu-drawer.allEvents') }}</div>
      </a>
      <div
        *ngFor="let category of categories()"
        class="category-tree__option"
        (touchend)="onTouchendMainCategory($event, category)"
        (click)="activeCategory.set(category); activeSubcategory.set(null)"
      >
        <ng-container *ngTemplateOutlet="categoryTmpl; context: { $implicit: category, hasIcon: true }" />
      </div>
    </div>
    <div>
      <div
        *ngFor="let category of categories()"
        class="category-tree__subcategory"
        [ngClass]="{ 'category-tree__subcategory--active': category === activeCategory() }"
      >
        <a
          [routerLink]="[i18nService.isEnglishActive ? '/en' : '/', category.slug]"
          class="category-tree__option"
          (click)="activeCategory.set(category); activeSubcategory.set(null); drawer.close()"
        >
          <div>
            <span>{{ t('shared.category-mega-menu-drawer.allEvents') }}</span>
          </div>
        </a>
        <a
          [routerLink]="[i18nService.isEnglishActive ? '/en' : '/', category.slug, subcategory.slug]"
          *ngFor="let subcategory of category.subcategory"
          class="category-tree__option"
          (click)="activeSubcategory.set(subcategory); drawer.close()"
        >
          <ng-container *ngTemplateOutlet="categoryTmpl; context: { $implicit: subcategory, hasIcon: false }" />
        </a>
      </div>
      <div class="category-tree__events-in-city">
        <div *ngIf="cities().length && !activeCategory() && !activeSubcategory()">
          <p class="h5">{{ t('shared.category-mega-menu.allEventsInCity') }}</p>
          <div class="category-tree__cities" *ngIf="cities().length > 0 && !activeCategory() && !activeSubcategory()">
            <span *ngFor="let city of cities() | slice: 0 : visibleCitesLength">
              <a
                *ngIf="city"
                eb-button
                ebType="secondary"
                ebSize="small"
                [routerLink]="[i18nService.isEnglishActive ? '/en' : '/', 'miasto', city.slug]"
                (click)="drawer.close()"
                >{{ city.name }}</a
              >
            </span>
          </div>
        </div>

        <div *ngFor="let category of categories()">
          <div [ngClass]="activeCategory() === category && !activeSubcategory() ? '' : 'hidden'">
            <p class="h5" *ngIf="category.mostPopularCities?.length">{{ t('shared.category-mega-menu.allEventsInCity') }}</p>
            <div class="category-tree__cities">
              <span *ngFor="let mostPopularCity of category.mostPopularCities | slice: 0 : visibleCitesLength">
                <a
                  *ngIf="mostPopularCity?.name"
                  eb-button
                  ebType="secondary"
                  ebSize="small"
                  [routerLink]="[i18nService.isEnglishActive ? '/en' : '/', category.slug, 'miasto', mostPopularCity.slug]"
                  (click)="drawer.close()"
                  >{{ mostPopularCity.name }}</a
                >
              </span>
            </div>
          </div>
          <div *ngFor="let subcategory of category.subcategory" [ngClass]="activeSubcategory() === subcategory ? '' : 'hidden'">
            <p class="h5" *ngIf="subcategory.mostPopularCities?.length">{{ t('shared.category-mega-menu.allEventsInCity') }}</p>
            <div class="category-tree__cities">
              <span *ngFor="let mostPopularCity of subcategory.mostPopularCities | slice: 0 : visibleCitesLength">
                <a
                  *ngIf="mostPopularCity?.name"
                  eb-button
                  ebType="secondary"
                  ebSize="small"
                  [routerLink]="[
                    i18nService.isEnglishActive ? '/en' : '/',
                    category.slug,
                    subcategory.slug,
                    'miasto',
                    mostPopularCity.slug
                  ]"
                  (click)="drawer.close()"
                  >{{ mostPopularCity.name }}</a
                >
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</eb-custom-drawer>
<div class="hidden">
  <span *ngFor="let city of cities() | slice: 0 : visibleCitesLength">
    <a *ngIf="city" [routerLink]="[i18nService.isEnglishActive ? '/en' : '/', 'miasto', city.slug]">{{ city.name }}</a>
  </span>
  <div *ngFor="let category of categories()">
    <span *ngFor="let mostPopularCity of category.mostPopularCities | slice: 0 : visibleCitesLength">
      <a
        *ngIf="mostPopularCity?.name"
        [routerLink]="[i18nService.isEnglishActive ? '/en' : '/', category.slug, 'miasto', mostPopularCity.slug]"
        (click)="drawer.close()"
        >{{ mostPopularCity.name }}</a
      >
    </span>
    <div *ngFor="let subcategory of category.subcategory">
      <span *ngFor="let mostPopularCity of subcategory.mostPopularCities | slice: 0 : visibleCitesLength">
        <a
          *ngIf="mostPopularCity?.name"
          [routerLink]="[i18nService.isEnglishActive ? '/en' : '/', category.slug, subcategory.slug, 'miasto', mostPopularCity.slug]"
          (click)="drawer.close()"
          >{{ mostPopularCity.name }}</a
        >
      </span>
    </div>
  </div>
</div>

<ng-template #categoryTmpl let-category let-routerLink="routerLink" let-hasIcon="hasIcon">
  <div (touchstart)="onTouchmoveCategory($event, category)" (touchend)="onTouchendCategory($event, category)">
    <eb-icon [key]="category?.icon | ebCategoryIcon" />
    <span>
      {{ category?.categoryName }}
    </span>
    <eb-icon *ngIf="hasIcon" key="chevron-right" class="ml-auto" />
  </div>
</ng-template>

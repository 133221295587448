import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  ViewEncapsulation,
  inject, InputSignal, input
} from '@angular/core';

@Component({
    selector: 'eb-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.less'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: { class: 'block' },
})
export class EbTopbarComponent {
    public elementRef = inject(ElementRef);

    backgroundColor: InputSignal<string | undefined> = input();
    @HostBinding('class.eb-topbar-menu--active') @Input() isActive = false;
}

import { HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

export class UrlHelper {
    static readonly loginPostfix = '/LoginRegister?returnUrl=';

    static addQueryParams(url: string, queryParams: any, slug?: string, eventType?: string): string {
        let params = new HttpParams({ fromObject: queryParams });
        params = params.append('utm_content', eventType ?? '');
        params = params.append('utm_term', slug ?? '');
        const result = `${url}?${encodeURIComponent(params.toString())}`;

        return result;
    }

    static getLoginUrl(isEnglishOn: boolean): string {
        // adres logowania ma konkretny przyrostek, który jest stały pomiędzy środowiskami i na końcu
        // potrzebuje w parametrze przekazać url na który ma przekierować po zalogowaniu
        return isEnglishOn
            ? environment.shopUrl + '/en' + this.loginPostfix + window.location.origin + '/en'
            : environment.shopUrl + this.loginPostfix + window.location.origin;
    }

    static openExternalUrl(url: string, target: TargetType): void {
        // Poniższy regex służy do zamienienia http:// albo https:// na pusty string
        const preparedUrl = url.replace(/^https?:\/\//, '');
        window.open(`https://${preparedUrl}`, target, 'noopener');
    }
}

export type TargetType = '_blank' | '_self';

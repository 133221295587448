import { CommonModule, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    PLATFORM_ID,
    TransferState,
    inject,
    signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import {
    ActivationEnd,
    NavigationEnd,
    NavigationSkipped,
    NavigationStart,
    ResolveEnd,
    Router,
    RouterOutlet,
    Scroll,
} from '@angular/router';
import { AppInsightsWebModule } from '@e-bilet/app-insights-web';
import { SyneriseModule } from '@e-bilet/synerise';
import { UiButtonsModule } from '@e-bilet/ui-buttons';
import { UiDrawerModule } from '@e-bilet/ui-drawer';
import { UiTicketPanelModule } from '@e-bilet/ui-ticket-panel';
import { UiTopbarsModule } from '@e-bilet/ui-topbars';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { SyneriseInitializerService } from '../../../../libs/synerise/src/lib/synerise-initializer.service';
import { BLACK_WEEK_DATE_KEY, UOKIK_DATE_KEY, CHRISTMAS_DATE_KEY } from '../consts/transfer-state-keys';
import { environment } from '../environments/environment';
import {
    BLACK_WEEK_START_DATE,
    BLACK_WEEK_END_DATE,
    CHRISTMAS_START_DATE,
    CHRISTMAS_END_DATE,
    UOKIK_START_DATE,
    UOKIK_END_DATE,
} from '../consts/special-dates.const';
import { PageLoadingSpinnerComponent } from './page-loading-spinner/page-loading-spinner.component';
import { IFrame } from './rest-api/models/iframe';
import { IframeService } from './services/iframe.service';
import { InternationalizationService } from './services/internationalization.service';
import { ShopQueueService } from './services/shop-queue.service';
import { CookiesManagerComponent } from './shared/components/cookies-manager/cookies-manager.component';
import { EbPortalMasterPageComponent } from './shared/components/portal-master-page/portal-master-page.component';
import { TranslocoRootModule } from './transloco-root.module';
@Component({
    selector: 'eb-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        RouterOutlet,
        UiDrawerModule,
        UiTicketPanelModule,
        UiButtonsModule,
        FormsModule,
        HttpClientModule,
        UiTopbarsModule,
        TranslocoRootModule,
        SyneriseModule,
        AppInsightsWebModule,
        EbPortalMasterPageComponent,
        CookiesManagerComponent,
        SvgIconComponent,
        PageLoadingSpinnerComponent,
    ],
})
export class AppComponent {
    private readonly _router = inject(Router);
    private readonly _platformId = inject(PLATFORM_ID);
    private readonly _transferState = inject(TransferState);
    private readonly _i18nService = inject(InternationalizationService);
    private readonly _shopQueueService = inject(ShopQueueService);
    private readonly _syneriseInitializerService = inject(SyneriseInitializerService);
    private _destroyRef = inject(DestroyRef);
    private _routerEvents$ = this._router.events;
    private readonly _iframeService = inject(IframeService);
    public isSsr = isPlatformServer(this._platformId);

    isPageLoading = signal(false);
    isDialogVisible = false;
    constructor() {
        this._handleTransferState();
        if (isPlatformBrowser(this._platformId)) {
            // wyłączenie ssr cache interceptora po przejściu na kolejną podstronę
            this._routerEvents$.subscribe((evt: any) => {
                if (evt instanceof NavigationEnd) {
                    this._shopQueueService.clearQueue();
                }

                if (
                    // usunięcie blura ze strony zaczepione na wiele eventów, by zminimalizować ryzyko nieusunięcia blura po zakończonej nawigacji
                    evt instanceof Scroll ||
                    evt instanceof NavigationEnd ||
                    evt instanceof NavigationSkipped ||
                    evt instanceof ActivationEnd ||
                    evt instanceof ResolveEnd
                ) {
                    this.isPageLoading.set(false);
                } else if (evt instanceof NavigationStart && !this._i18nService.changingLanguage) {
                    this.isPageLoading.set(true);
                }
            });
        } else {
            this._iframeService.iframe$.pipe(takeUntilDestroyed()).subscribe((iframe: IFrame | undefined) => {
                if (!iframe) {
                    this._syneriseInitializerService.addSyneriseScript(environment.syneriseTrackerKey, true);
                }
            });
        }
    }

    private _handleTransferState(): void {
        if (isPlatformServer(this._platformId)) {
            const today = new Date().getTime();
            this._transferState.set(
                UOKIK_DATE_KEY,
                this._isTodayWithinDateRange(UOKIK_START_DATE, today, UOKIK_END_DATE),
            );
            this._transferState.set(
                BLACK_WEEK_DATE_KEY,
                this._isTodayWithinDateRange(BLACK_WEEK_START_DATE, today, BLACK_WEEK_END_DATE),
            );
            this._transferState.set(
                CHRISTMAS_DATE_KEY,
                this._isTodayWithinDateRange(CHRISTMAS_START_DATE, today, CHRISTMAS_END_DATE),
            );
        }
    }

    private _isTodayWithinDateRange(startDate: string, today: number, endDate: string): boolean {
        return new Date(startDate).getTime() < today && today < new Date(endDate).getTime();
    }
}

import { Injectable } from '@angular/core';
import { DefaultUrlSerializer, UrlTree } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class CustomUrlSerializer extends DefaultUrlSerializer {
    parse(url: string): UrlTree {
        return super.parse(this._removeParentheses(url));
    }

    private _removeParentheses(url: string): string {
        return url.replace(/[()]/g, '');
    }
}

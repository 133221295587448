import { ChangeDetectionStrategy, Component, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';
import { EbDropdownPanel } from '../dropdown-panel.interface';

@Component({
    selector: 'eb-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EbDropdownComponent implements EbDropdownPanel {
    @ViewChild(TemplateRef) readonly templateRef: TemplateRef<void> | undefined;
    @Output() readonly closed = new EventEmitter<void>();

    protected onDropdownClick(): void {
        if (this.closed.observed) {
            this.closed.emit();
        }
    }
}

/* eslint-disable @typescript-eslint/no-empty-function */
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    Input,
    ViewChild,
    forwardRef,
    inject,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ITreeOption, UiTreeSelectModule } from '@e-bilet/ui-tree-select';
import { EbTreeSelectComponent } from 'libs/ui-tree-select/src/lib/tree-select/tree-select.component';
import { Province } from '../../../rest-api/models/province.model';
import {
    EbInputSize,
    EbInputTheme,
} from './../../../../../../../libs/ui-form-item/src/lib/form-item/form-item.component';
import { TranslocoModule } from '@jsverse/transloco';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'eb-location-tree-select',
    templateUrl: './location-tree-select.component.html',
    styleUrls: ['./location-tree-select.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => EbLocationTreeSelectComponent), multi: true },
    ],
    standalone: true,
    imports: [
      CommonModule,
      UiTreeSelectModule,
      TranslocoModule,
      FormsModule,
    ]
})
export class EbLocationTreeSelectComponent implements ControlValueAccessor {
    private readonly _changeDetectorRef = inject(ChangeDetectorRef);
    public readonly elementRef = inject(ElementRef);

    private _selected: ITreeOption<Province> | null = null;

    protected get selected(): ITreeOption<Province> | null {
        return this._selected;
    }

    @Input({ required: true }) options: ITreeOption<Province>[] = [];
    @Input() onlyIcon = false;
    @Input() size: EbInputSize = 'default';
    @Input() theme: EbInputTheme = 'dark';
    @Input() origin?: CdkOverlayOrigin;
    @Input() formElement?: HTMLFormElement;

    @HostBinding('class.eb-location-tree-select') protected get isLocationTreeSelect(): boolean {
        return true;
    }

    @ViewChild(EbTreeSelectComponent) readonly ebTreeSelectComponent!: EbTreeSelectComponent<any>;

    private _onTouched: () => void = () => {};
    private _onChange: (value: ITreeOption<Province> | null) => void = () => {};

    public writeValue(value: ITreeOption<Province> | null): void {
        this._selected = value;
        this._changeDetectorRef.markForCheck();
    }

    public registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    protected select(value: ITreeOption<Province> | null): void {
        this._selected = value;
        this._onChange(this._selected);
    }
}

<eb-tree-select
  class="eb-control"
  [label]="t('shared.location-tree-select.location')"
  [size]="size"
  [theme]="theme"
  [ngModel]="selected"
  (ngModelChange)="select($event)"
  icon="location-pin"
  [onlyIcon]="onlyIcon"
  placeholder="{{ t('shared.location-tree-select.selectCity') }}"
  allFromCategory="go"
  [options]="options"
  [selectedTemplate]="selectedLocationTemplate"
  [optionTemplate]="locationOptionTemplate"
  [origin]="origin"
  [formElement]="formElement"
  *transloco="let t"
/>

<ng-template #selectedLocationTemplate let-option>
  {{ option.label }}<ng-container *ngIf="option.parent">, {{ option.parent.label }}</ng-container>
</ng-template>

<ng-template #locationOptionTemplate let-location let-flat="flat">
  {{ location.label }}<ng-container *ngIf="location.parent && flat">, {{ location.parent.label }}</ng-container>
</ng-template>

import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  inject, InputSignal, input, WritableSignal, signal
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DeviceService } from 'libs/device/src/lib/device.service';
import { Article } from '../../../../../apps/portal/src/app/rest-api/models/article.interface';

type articleSize = 'small' | 'normal' | 'large' | 'extra-large';

@Component({
    selector: 'eb-article-card',
    templateUrl: './article-card.component.html',
    styleUrls: ['./article-card.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EbArticleCardComponent implements OnInit {
    private readonly _destroyRef = inject(DestroyRef);

    article: InputSignal<Article | undefined> = input();
    skeleton: InputSignal<boolean> = input(false);
    size: InputSignal<articleSize> = input<articleSize>('normal');
    public amountOfTagsVisible: WritableSignal<number> = signal(2);
    public readonly AMOUNT_OF_TAGS_ON_MOBILE = 1;
    public readonly AMOUNT_OF_TAGS_ON_PC = 2;

    constructor(
        private _deviceService: DeviceService,
    ) {}

    public ngOnInit(): void {
        this._deviceService.isMobile$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((isMobile: boolean) => {
            if (this.size() !== 'small') {
                this.amountOfTagsVisible.set(isMobile ? this.AMOUNT_OF_TAGS_ON_MOBILE : this.AMOUNT_OF_TAGS_ON_PC);
            } else {
                this.amountOfTagsVisible.set(this.AMOUNT_OF_TAGS_ON_MOBILE);
            }
        });
    }
}

import { SpecialDateService } from '../../../services/special-date.service';
/* eslint-disable @typescript-eslint/no-empty-function */
import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    ElementRef,
    EventEmitter,
    forwardRef,
    HostListener,
    inject,
    input,
    Input,
    InputSignal,
    OnInit,
    Output,
    PLATFORM_ID,
    QueryList,
    signal,
    TemplateRef,
    ViewChild,
    ViewChildren,
    WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { GtmService } from '@e-bilet/gtm';
import { UiButtonsModule } from '@e-bilet/ui-buttons';
import { EbBuyTicketButtonComponent } from '@e-bilet/ui-buy-ticket-button';
import { UiPipeModule } from '@e-bilet/ui-pipe';
import { EbSelectComponent, ISelectOption, ISelectOptionGroup, UiSelectModule } from '@e-bilet/ui-select';
import { TRANSLOCO_SCOPE, TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { AggregatedGa4EventsService } from 'libs/gtm/src/lib/aggregated-ga4-events.service';
import { DateRangeService } from 'libs/ui-date-picker/date-range.service';
import { EbInputSize, EbInputTheme } from 'libs/ui-form-item/src/lib/form-item/form-item.component';
import { EbSelectOptionComponent } from 'libs/ui-select/src/lib/select-option/select-option.component';
import { BehaviorSubject, combineLatest, of, timer } from 'rxjs';
import { debounce, distinctUntilChanged, finalize, map, startWith, switchMap, tap } from 'rxjs/operators';
import { Ga4ListNames } from '../../../../../../../libs/gtm/src/lib/models/ga4-list-names.enum';
import { GtmVariableNames } from '../../../../../../../libs/gtm/src/lib/models/gtm-variable-names.enum';
import { SearchInputVirtualPageView } from '../../../../../../../libs/gtm/src/lib/virtual-page-view';
import { DateParams } from '../../../../../../../libs/ui-date-picker/src/lib/date-params';
import { IconComponent } from '../../../../../../../libs/ui-icons/src/lib/icon/icon.component';
import { nameToId } from '../../../../consts/name-to-id';
import { HomeRestService } from '../../../rest-api/home-rest.service';
import { SearchRequest } from '../../../rest-api/models/search-request.model';
import {
    ArtistSearch,
    EventSearch,
    PlaceSearch,
    SearchResponse,
    TitleSearch,
} from '../../../rest-api/models/search-response.model';
import {
    TitleEventAvailabilities,
    TitleEventAvailabilityData,
} from '../../../rest-api/models/title-event-availability.models';
import { TitleEvent } from '../../../rest-api/models/title-page.model';
import { ISearchPageQueryParams } from '../../../search-page/search-page-query-params.interface';
import { NavigationService } from '../../../services/navigation.service';
import { SearchService } from '../../../services/search.service';
import { ShopQueueService } from '../../../services/shop-queue.service';
import { SearchProposedStoreService } from '../../../stores/search-proposed-store.service';
import { TitleEventAvailabilityStoreService } from '../../../stores/title-event-availability-store.service';
import { CalendarHelper } from '../calendar/calendar.helper';
import { SearchSelectContentComponent } from '../search-select-content/search-select-content.component';
import { ISearchParam } from './search-param.interface';
import { ISearchSelectValue, SearchOptionGroup } from './search-select-value.interface';

@Component({
    selector: 'eb-search-select',
    templateUrl: './search-select.component.html',
    styleUrls: ['./search-select.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: { scope: 'search-page', alias: 'searchPage' },
        },
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => EbSearchSelectComponent), multi: true },
    ],
    host: { class: 'block' },
    standalone: true,
    imports: [
        CommonModule,
        UiSelectModule,
        UiButtonsModule,
        UiPipeModule,
        TranslocoModule,
        SearchSelectContentComponent,
        EbBuyTicketButtonComponent,
        IconComponent,
    ],
})
export class EbSearchSelectComponent implements OnInit, ControlValueAccessor {
    private _lastRequest!: SearchRequest;
    private _destroyRef = inject(DestroyRef);
    private readonly _searchParam$ = new BehaviorSubject<ISearchParam | null>(null);

    private readonly _changeDetectorRef = inject(ChangeDetectorRef);
    private readonly _homeRestService = inject(HomeRestService);
    private readonly _searchProposedStoreService = inject(SearchProposedStoreService);
    private readonly _searchService = inject(SearchService);
    private readonly _router = inject(Router);
    private readonly _titleEventAvailabilityStoreService = inject(TitleEventAvailabilityStoreService);
    private readonly _shopQueueService = inject(ShopQueueService);
    private readonly _gtmService = inject(GtmService);
    private readonly _dateRangeService = inject(DateRangeService);
    private readonly _platformId = inject(PLATFORM_ID);
    private readonly _navigationService = inject(NavigationService);
    private readonly _translocoService = inject(TranslocoService);
    protected readonly specialDateService = inject(SpecialDateService);
    public readonly aggregatedGa4EventsService = inject(AggregatedGa4EventsService);

    readonly titleEventAvailabilities = TitleEventAvailabilities;
    readonly ga4ListNames = Ga4ListNames;

    options: WritableSignal<ISelectOptionGroup<SearchOptionGroup, any>[]> = signal([]);
    titleEventAvailabilityDataMap: WritableSignal<Map<string, TitleEventAvailabilityData>> = signal(
        new Map<string, TitleEventAvailabilityData>(),
    );

    value: ISelectOption<any> | null = null;
    loading: WritableSignal<boolean> = signal(false);
    proposedResult: WritableSignal<SearchResponse | undefined> = signal(undefined);
    virtualPageView: SearchInputVirtualPageView | undefined;
    listName = Ga4ListNames.SearchSuggest;
    listId = nameToId(Ga4ListNames.SearchSuggest);
    preventParentSubmit = false;

    public get lastRequest(): SearchRequest {
        return this._lastRequest as SearchRequest;
    }

    @Input() set searchParams(params: ISearchParam) {
        this._searchParam$.next(params);
    }

    showIcon: InputSignal<boolean> = input<boolean>(true);
    @Input() showSuffixIcon = true;
    size: InputSignal<EbInputSize> = input<EbInputSize>('default');
    theme: InputSignal<EbInputTheme> = input<EbInputTheme>('dark');
    @Input() overlayOrigin?: ElementRef;
    @Input() debounceTime: number | null = null;
    @Input() autoFocus = false;
    @Input() isQuickSearchInputGroup = false;
    @Output() readonly searching = new EventEmitter<string>();
    @Output() readonly selectedChange = new EventEmitter<ISearchSelectValue>();

    @ViewChild(EbSelectComponent, { static: true }) readonly ebSelectComponent!: EbSelectComponent<
        ISelectOptionGroup<SearchOptionGroup, any>
    >;

    @ViewChild('titleOptionTemplate', { static: false }) readonly titleOptionTemplate!: TemplateRef<any>;
    @ViewChild('eventOptionTemplate', { static: false }) readonly eventOptionTemplate!: TemplateRef<any>;
    @ViewChild('artistsOptionTemplate', { static: false }) readonly artistsOptionTemplate!: TemplateRef<any>;
    @ViewChild('placeOptionTemplate', { static: false }) readonly placeOptionTemplate!: TemplateRef<any>;

    @ViewChildren(EbSelectOptionComponent) readonly ebSelectOptionComponents:
        | QueryList<EbSelectOptionComponent<any>>
        | undefined;

    selectOptionFocusCounter: number | null = null;

    @HostListener('keydown', ['$event']) protected handleKeyboardEvent(event: KeyboardEvent): void {
        switch (event.key) {
            case 'Enter':
                if (this.isQuickSearchInputGroup) {
                    this.preventParentSubmit = true;
                    if (isPlatformBrowser(this._platformId)) {
                        setTimeout(() => (this.preventParentSubmit = false), 100);
                    } else {
                        this.preventParentSubmit = false;
                    }
                }
                if ((this.selectOptionFocusCounter || this.selectOptionFocusCounter === 0) && !this.options.length) {
                    this.selectTitleOption(
                        new MouseEvent(''),
                        this.proposedResult()?.titleSearch[this.selectOptionFocusCounter],
                        this.selectOptionFocusCounter,
                    );
                } else {
                    this._router.navigate(['./wydarzenia'], { queryParams: this._mapToSearchPageQueryParams() });
                }
                break;
            case 'ArrowDown':
                if (
                    this.ebSelectOptionComponents &&
                    this.selectOptionFocusCounter !== null &&
                    this.selectOptionFocusCounter < this.ebSelectOptionComponents?.length - 1
                ) {
                    this.selectOptionFocusCounter++;
                } else if (this.ebSelectOptionComponents && !this.selectOptionFocusCounter) {
                    this.selectOptionFocusCounter = 0;
                }
                break;
            case 'ArrowUp':
                if (
                    this.ebSelectOptionComponents &&
                    this.selectOptionFocusCounter !== null &&
                    this.selectOptionFocusCounter > 0
                ) {
                    this.selectOptionFocusCounter--;
                }
                break;
        }
    }

    protected searchSelectPlaceholderText = signal('');
    protected searchText = signal('');

    private _onTouched: () => void = () => {};
    private _onChange: (value: ISelectOption<any> | null) => void = () => {};

    public ngOnInit(): void {
        if (!isPlatformBrowser(this._platformId)) {
            return;
        }

        this._searchProposedStoreService
            .get()
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((result) => {
                this.proposedResult.set(result);
            });

        combineLatest([this.ebSelectComponent.searching.pipe(startWith('')), this._searchParam$])
            .pipe(
                tap(() => this.loading.set(true)),
                map(([text, searchParams]) => this._mapToSearchRequest(text, searchParams)),
                tap((request) => {
                    this.virtualPageView = new SearchInputVirtualPageView(encodeURIComponent(request.text?.trim()));
                    this._lastRequest = request;
                }),
                debounce(() => (this.debounceTime ? timer(this.debounceTime) : of({}))),
                distinctUntilChanged(),
                switchMap((request) =>
                    request.text
                        ? this._homeRestService
                              .search(this._mapDateParamToDateRange(request))
                              .pipe(map((searchResponse) => this._mapSearchResponseToOptionGroup(searchResponse)))
                        : of([]),
                ),
                finalize(() => this.loading.set(false)),
                takeUntilDestroyed(this._destroyRef),
            )
            .subscribe((searchOptions) => {
                this.options.set(searchOptions);
                if (this.autoFocus) {
                    this.focus();
                }
            });

        this._router.events.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.ebSelectComponent.close();
            }
        });

        this._translocoService.selectTranslate('shared.search-select.placeholder').subscribe((placeholder) => {
            this.searchSelectPlaceholderText.set(placeholder);
        });

        this._translocoService.selectTranslate('shared.search-select.search').subscribe((search) => {
            this.searchText.set(search);
        });
    }

    public writeValue(obj: ISelectOption<any>): void {
        this.value = obj;
        this._changeDetectorRef.detectChanges();
    }

    public registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    public focus(): void {
        this.ebSelectComponent.focus();
    }

    protected selectTitleOption(event: MouseEvent, titleSearch: TitleSearch | undefined, index: number): void {
        if (!titleSearch) {
            return;
        }

        this._preventPropagation(event);
        this._selectItem(titleSearch, index);
        if (this.selectedChange.observed) {
            this.selectedChange.emit({ group: 'title', item: titleSearch });
        }
        this._navigationService.navigateToTitle(titleSearch);
    }

    protected handleSearchClick(
        event: MouseEvent,
        eventSearch: EventSearch,
        index: number,
        eventTitle: TitleEvent,
    ): void {
        this._preventPropagation(event);
        if (
            this.titleEventAvailabilityDataMap().get(eventSearch.id)?.titleEventAvailability ===
            this.titleEventAvailabilities.AVAILABLE
        ) {
            this._redirectToShop(event, eventTitle);
        } else {
            this._selectEventOption(eventSearch, index);
        }
    }

    private _redirectToShop(event: MouseEvent, eventSearch: TitleEvent): void {
        this._shopQueueService.queueRedirectToShop(eventSearch, '', '', event.ctrlKey, this.virtualPageView);
    }

    private _selectEventOption(eventSearch: EventSearch, index: number): void {
        this._selectItemFromEvent(eventSearch, index);
        if (this.selectedChange.observed) {
            this.selectedChange.emit({ group: 'event', item: eventSearch });
        }
        this._router.navigate(['/', eventSearch.category, eventSearch.subcategory, eventSearch.slug]);
    }

    protected selectArtistOption(event: MouseEvent, artistSearch: ArtistSearch): void {
        this._preventPropagation(event);
        if (this.selectedChange.observed) {
            this.selectedChange.emit({ group: 'artist', item: artistSearch });
        }
        this._router.navigate(['/artysta', artistSearch.slug]);
    }

    protected selectPlaceOption(event: MouseEvent, placeSearch: PlaceSearch): void {
        this._preventPropagation(event);
        if (this.selectedChange.observed) {
            this.selectedChange.emit({ group: 'place', item: placeSearch });
        }
        this._router.navigate(['/miejsce', placeSearch.slug]);
    }

    protected selectGroup(): void {
        if (this.selectedChange.observed) {
            this.selectedChange.emit({ group: 'place' });
        }
        this.ebSelectComponent.close();
        this._router.navigate(['./wydarzenia'], { queryParams: this._mapToSearchPageQueryParams() });
    }

    protected getTemplate(group: SearchOptionGroup): TemplateRef<any> {
        switch (group) {
            case 'title':
                return this.titleOptionTemplate;
            case 'event':
                return this.eventOptionTemplate;
            case 'artist':
                return this.artistsOptionTemplate;
            case 'place':
                return this.placeOptionTemplate;
        }
    }

    private _selectItem(titleSearch: TitleSearch, index: number): void {
        this._gtmService.setGa4Event(
            [this._gtmService.mapTitleSearchToGa4Item(titleSearch, this.listName, this.listId, index)],
            GtmVariableNames.SelectItem,
            this.listName,
            this.listId,
        );

        if (titleSearch.isPromotion) {
            this._gtmService.setGa4Event(
                [
                    this._gtmService.mapTitleSearchToGa4Item(
                        titleSearch,
                        this.listName,
                        this.listId,
                        index,
                        'Promocja',
                        'promocja',
                    ),
                ],
                GtmVariableNames.SelectPromotion,
                undefined,
                undefined,
                undefined,
                'Promocja',
                'promocja',
            );
        }
    }

    private _selectItemFromEvent(eventSearch: EventSearch, index: number): void {
        this._gtmService.setGa4Event(
            [this._gtmService.mapEventSearchToGa4Item(eventSearch, this.listName, this.listId, index)],
            GtmVariableNames.SelectItem,
            this.listName,
            this.listId,
        );

        if (eventSearch.isPromotion) {
            this._gtmService.setGa4Event(
                [
                    this._gtmService.mapEventSearchToGa4Item(
                        eventSearch,
                        this.listName,
                        this.listId,
                        index,
                        'Promocja',
                        'promocja',
                    ),
                ],
                GtmVariableNames.SelectPromotion,
                undefined,
                undefined,
                undefined,
                'Promocja',
                'promocja',
            );
        }
    }

    protected clear(): void {
        this._router.navigate(['./wydarzenia']);
    }

    protected emitSelectedChange(value: ISearchSelectValue): void {
        if (this.selectedChange.observed) {
            this.selectedChange.emit(value);
        }
    }

    protected checkAvailability(eventSearch: EventSearch): void {
        this._titleEventAvailabilityStoreService
            .find$(eventSearch.id)
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((availabilityData: TitleEventAvailabilityData) => {
                const availabilityDataMap = this.titleEventAvailabilityDataMap();
                availabilityDataMap.set(eventSearch.id, availabilityData);
                this.titleEventAvailabilityDataMap.set(new Map(availabilityDataMap));
            });

        this._titleEventAvailabilityStoreService
            .load({
                events: [this._mapFromEventSearchToTitleEvent(eventSearch)],
                cacheLong: !!eventSearch.webShopMaxDelay,
            })
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe();
    }

    protected emitSearching(value: string): void {
        if (this.searching.observed) {
            this.searching.emit(value);
        }
    }

    private _preventPropagation(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();
    }

    private _mapToSearchRequest(text: string, searchParam: ISearchParam | null): SearchRequest {
        const request: SearchRequest = {
            text,
        };
        if (searchParam?.dateRange) {
            if (searchParam.dateRange.length === 2) {
                request.dateFrom = CalendarHelper.getStartOfDay(searchParam.dateRange[0] as Date);
                request.dateTo = CalendarHelper.getEndOfDay(searchParam.dateRange[1] as Date);
            } else {
                request.date = searchParam.dateRange as DateParams;
            }
        }
        if (searchParam?.location) {
            if (searchParam.location.parent) {
                request.province = searchParam.location.parent.value.name;
                request.city = searchParam.location.value.name;
                request.citySlug = searchParam.location.value.slug;
                request.provinceSlug = searchParam.location.parent.value.slug;
            } else {
                request.province = searchParam.location.value.name;
                request.provinceSlug = searchParam.location.value.slug;
            }
        }
        return request;
    }

    private _mapDateParamToDateRange(request: SearchRequest): SearchRequest {
        if (request.date) {
            [request.dateFrom, request.dateTo] = this._dateRangeService.mapDateParamToLightFormatDateRange(
                request.date,
            );
        }
        return request;
    }

    private _mapToSearchPageQueryParams(): ISearchPageQueryParams | null {
        return this._searchService.mapToSearchPageQueryParams(this._lastRequest, false);
    }

    private _mapSearchResponseToOptionGroup(
        searchResponse: SearchResponse,
    ): ISelectOptionGroup<SearchOptionGroup, any>[] {
        return [
            {
                type: 'title',
                label: 'searchPage.titlesTabHeader',
                items: searchResponse.titleSearch,
                total: searchResponse.coutTitle,
            },
            {
                type: 'event',
                label: 'searchPage.eventsTabHeader',
                items: searchResponse.eventsearch,
                total: searchResponse.countEvents,
            },
            {
                type: 'artist',
                label: 'searchPage.artistsTabHeader',
                items: searchResponse.artistSearch,
                total: searchResponse.countArtist,
            },
            {
                type: 'place',
                label: 'searchPage.placesTabHeader',
                items: searchResponse.placeSearch,
                total: searchResponse.countPlace,
            },
        ];
    }

    private _mapFromEventSearchToTitleEvent(eventSearch: EventSearch): TitleEvent {
        const { address, dateEvent, ...otherValues } = eventSearch;

        return {
            ...otherValues,
            date: dateEvent,
            city: address.city,
            placeName: address.namePlace,
            titleId: '',
            province: null,
            titleTitle: null,
            customDateText: null,
        };
    }
}

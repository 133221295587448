import { Routes } from '@angular/router';
import { IframeGuard } from './infrastructure/iframe.guard';
import { RedirectLanguageGuard } from './infrastructure/redirect-language.guard';
import { RedirectUrlGuard } from './infrastructure/redirect-url.guard';
import { IRouteData } from './infrastructure/route-data.interface';
import { CacheTime, WebrockCacheGuard } from './infrastructure/webrock-cache.guard';
import { ArtistPageGuard } from './artist-page/artist-page.guard';
import { ARTIST_PAGE_ROUTES } from './artist-page/artist-page.routes';

const children = [
    {
        path: 'wydarzenia',
        data: {
            cacheTime: CacheTime.MINUTE_3,
            isQuickSearchHidden: true,
            isSimpleSearchVisible: true,
        },
        loadChildren: () => import('./search-page/search-page-routes').then((m) => m.SEARCH_ROUTES),
    },
    {
        path: 'kontakt',
        loadComponent: () => import('./contact-page/contact-page.component').then((m) => m.EbContactPageComponent),
    },
    {
        path: 'centrum-pomocy',
        loadChildren: () => import('./faq-page/faq-page.module').then((m) => m.FaqPageModule),
    },
    {
        path: 'newsletter',
        loadComponent: () => import('./newsletter-page/newsletter-page.component').then((m) => m.NewsletterPageComponent),
    },
    {
        path: 'miejsce',
        data: { cacheTime: CacheTime.MINUTE_3 } as IRouteData,
        loadComponent: () =>
            import('./place-list-page/place-list-page.component').then((m) => m.PlaceListPageComponent),
    },
    {
        path: 'miejsce/:placeSlug',
        data: { cacheTime: CacheTime.MINUTE_3 } as IRouteData,
        loadChildren: () => import('./place-page/place-page.routes').then((m) => m.PLACE_PAGE_ROUTES),
    },
    {
        path: 'artysta',
        data: { cacheTime: CacheTime.MINUTE_3 } as IRouteData,
        loadChildren: () => import('./artist-list-page/artist-list.routes').then((m) => m.ARTIST_LIST_ROUTES),
    },
    {
        path: 'artysta/:artistSlug',
        data: { cacheTime: CacheTime.MINUTE_3 } as IRouteData,
        loadChildren: () => import('./artist-page/artist-page.routes').then((m) => m.ARTIST_PAGE_ROUTES),
    },
    {
        path: 'organizatorzy/:slug',
        data: { cacheTime: CacheTime.MINUTE_3 } as IRouteData,
        loadChildren: () => import('./organizer-page/organizer.routes').then((m) => m.ORGANIZER_ROUTES),
    },
    {
        path: 'lp/:slug',
        data: { cacheTime: CacheTime.MINUTE_3 } as IRouteData,
        loadChildren: () => import('./landing-page/landing-page.routes').then((m) => m.LP_ROUTES),
    },
    {
        path: 'miasto/:city',
        data: { cacheTime: CacheTime.MINUTE_3 } as IRouteData,
        loadChildren: () =>
            import('./landing-location-page/landing-location-page.routes').then((m) => m.LP_LOCATION_ROUTES),
    },
    {
        path: '404',
        loadComponent: () => import('./error-404-page/error-404-page.component').then((m) => m.Error404PageComponent),
    },
    {
        path: '500',
        data: { cacheTime: CacheTime.NONE } as IRouteData,
        loadComponent: () => import('./error-500-page/error-500-page.component').then((m) => m.Error500PageComponent),
    },
    {
        path: ':category',
        data: { cacheTime: CacheTime.MINUTE_3 } as IRouteData,
        loadChildren: () => import('./category-page/category-page.routes').then((m) => m.CATEGORY_PAGE_ROUTES),
    },
    {
        path: ':category/miasto/:city',
        data: { cacheTime: CacheTime.MINUTE_3 } as IRouteData,
        loadChildren: () =>
            import('./landing-location-page/landing-location-page.routes').then((m) => m.LP_LOCATION_ROUTES),
    },
    {
        path: ':category/:subcategory',
        data: { cacheTime: CacheTime.MINUTE_3 } as IRouteData,
        loadChildren: () => import('./subcategory-page/subcategory-page.routes').then((m) => m.SUBCATEGORY_PAGE_ROUTES),
    },
    {
        path: ':category/:subcategory/miasto/:city',
        data: { cacheTime: CacheTime.MINUTE_3 } as IRouteData,
        loadChildren: () =>
            import('./landing-location-page/landing-location-page.routes').then((m) => m.LP_LOCATION_ROUTES),
    },
    {
        path: ':category/:subcategory/:title',
        data: { cacheTime: CacheTime.MINUTE_3 } as IRouteData,
        loadChildren: () => import('./title-page/title-page.routes').then((m) => m.TITLE_PAGE_ROUTES),
    },
    {
        path: '',
        data: {
            isQuickSearchHidden: true,
        },
        loadComponent: () => import('./home-page/home-page.component').then((m) => m.HomePageComponent),
    },
    {
        path: '**',
        redirectTo: '',
    },
];

export const routes: Routes = [
    {
        path: 'en',
        canActivate: [WebrockCacheGuard, RedirectUrlGuard, RedirectLanguageGuard, IframeGuard],
        children,
    },
    {
        path: '',
        canActivate: [WebrockCacheGuard, RedirectUrlGuard, RedirectLanguageGuard, IframeGuard],
        children,
    },
];

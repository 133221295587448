<eb-select
  [icon]="showIcon() ? 'search' : undefined"
  [label]="searchText()"
  [size]="size()"
  [theme]="theme()"
  [overlayOrigin]="overlayOrigin"
  [overlayWidth]="overlayOrigin?.nativeElement.offsetWidth"
  [loading]="loading()"
  [serverFilter]="true"
  [customValue]="true"
  [showSuffixIcon]="showSuffixIcon"
  (searching)="emitSearching($event)"
  (clearInput)="clear()"
  [placeholder]="searchSelectPlaceholderText()"
>
  <div class="p-3">
    <ng-container *ngIf="options().length; then searchResultTmpl; else proposedResultTmpl" />
  </div>

  <ng-template #searchResultTmpl>
    <ng-container *ngFor="let group of options()">
      <ng-container
        *ngTemplateOutlet="
          optionGroupTmpl;
          context: { $implicit: group.items, title: group.label, total: group.total, itemTmpl: getTemplate(group.type) }
        "
      />
    </ng-container>
  </ng-template>

  <ng-template #proposedResultTmpl>
    <ng-container *transloco="let t">
      <p class="option-group__name mb-3">{{ t('shared.search-select.featuredEvents') }}</p>
      <ng-container *ngFor="let item of proposedResult()?.titleSearch; let i = index">
        <eb-select-option [focused]="i === selectOptionFocusCounter" [option]="{ label: '', value: item, translationKey: '' }">
          <div>
            <ng-container *ngTemplateOutlet="titleOptionTemplate; context: { $implicit: item, i: i, isProposedResult: true }" />
          </div>
        </eb-select-option>
      </ng-container>
      <button eb-button *ngIf="(proposedResult()?.titleSearch?.length || 0) > 3" ebType="secondary" ebSize="small" (click)="selectGroup()">
        {{ t('shared.search-select.showAll') }}
      </button>
    </ng-container>
  </ng-template>

  <ng-template #optionGroupTmpl let-title="title" let-total="total" let-itemTmpl="itemTmpl" let-items>
    <p class="option-group__name mb-3" *transloco="let t">{{ t(title) }} ({{ total || 0 }})</p>
    <ng-container *ngFor="let item of items; let i = index">
      <eb-select-option [option]="item">
        <div>
          <ng-container
            *ngTemplateOutlet="
              itemTmpl;
              context: {
                $implicit: item,
                i: i,
                minPrice: titleEventAvailabilityDataMap().get(item.id)?.minPrice,
                isPromoted: titleEventAvailabilityDataMap().get(item.id)?.isPromoted
              }
            "
          />
        </div>
      </eb-select-option>
    </ng-container>
    @if (total > 3) {
      <button eb-button ebType="secondary" ebSize="small" (click)="selectGroup()" *transloco="let t">
        {{ t('shared.search-select.showAll') }}
      </button>
    }

    <div class="eb-divider option-group__divider"></div>
  </ng-template>

  <ng-template #titleOptionTemplate let-title let-i="i" let-isProposedResult="isProposedResult">
    <div
      ebIntersection
      [ebOnlyFirst]="true"
      (intersecting)="aggregatedGa4EventsService.titleSearchItemIntersecting(title, isProposedResult, i)"
      class="search-option"
      (click)="selectTitleOption($event, title, i)"
    >
      <div class="search-option__thumbnail">
        <img [src]="title.imageLandscape | media" [alt]="title.title" />
      </div>
      <div class="ml-3">
        <span class="search-option__title">{{ title.title }}</span>
        <span class="search-option__description eb-size--small line-height-3">
          <ng-container *ngIf="title.isPromotion">
            <span class="eb-promotion-label" [ngClass]="{ 'eb-black-week': specialDateService.isBlackWeek() }">{{
              specialDateService.promotionLabelText()
            }}</span>
            <span class="eb-text-divider"></span>
          </ng-container>
          <ng-container *ngIf="title.dateFrom && !title.isEventDateHidden">
            <span class="white-space-nowrap">{{ title.dateFrom | ebDate: title.dateTo }}</span>
            <span class="eb-text-divider"></span>
          </ng-container>
          <span *ngIf="title.place">{{ title.place }}</span>
        </span>
      </div>
    </div>
  </ng-template>

  <ng-template #eventOptionTemplate let-event let-i="i" let-minPrice="minPrice" let-isPromoted="isPromoted">
    <div
      ebIntersection
      [ebOnlyFirst]="true"
      (intersecting)="aggregatedGa4EventsService.eventSearchItemIntersecting(event)"
      class="search-option"
      (click)="handleSearchClick($event, event, i, event)"
    >
      <div class="search-option__thumbnail">
        <img [src]="event.imageLandscape | media" [alt]="event.title" />
      </div>
      <div class="ml-3" ebIntersection [ebOnlyFirst]="true" (intersecting)="checkAvailability(event)">
        <span class="search-option__title">{{ event.title }}</span>
        <span class="search-option__description eb-size--small">
          @if (!isPromoted && (minPrice | ebIsNumber)) {
            <span *transloco="let t">{{ t('shared.search-select.from') }} {{ minPrice | ebCurrency: 'PLN' }}</span>
          }

          <span *ngIf="isPromoted" class="eb-promotion-label" [ngClass]="{ 'eb-black-week': specialDateService.isBlackWeek() }">{{
            specialDateService.promotionLabelText()
          }}</span>
          <span *ngIf="(minPrice | ebIsNumber) || isPromoted" class="eb-text-divider"></span>
          <span *ngIf="!event.isEventDateHidden">{{ event.dateEvent | ebDate }}</span>
          <ng-container *ngIf="event.address.city">
            <span *ngIf="!event.isEventDateHidden" class="eb-text-divider"></span>
            <span>{{ event.address.city }}, {{ event.address.namePlace }}, {{ event.address.street }}</span>
          </ng-container>
        </span>
      </div>
      <div class="search-option__action">
        <ng-container [ngSwitch]="titleEventAvailabilityDataMap().get(event.id)?.titleEventAvailability">
          <ng-container *transloco="let t">
            <ng-container *ngSwitchCase="titleEventAvailabilities.AVAILABLE">
              <eb-buy-ticket-button [titleEvent]="event" [ebSize]="'small'" [virtualPageView]="virtualPageView">
                <eb-icon key="ticket" /> {{ t('shared.search-select.buyTicket') }}
                <ng-container eb-buy-ticket-queue>
                  <p class="eb-size--tiny search-queue">
                    <span [innerHtml]="t('libs.title-event-bar.redirectToShop')"></span>
                  </p>
                </ng-container>
              </eb-buy-ticket-button>
            </ng-container>
            <ng-container *ngSwitchCase="titleEventAvailabilities.CURRENTLY_UNAVAILABLE">
              <div class="flex flex-column align-items-center mr-3">
                <p class="eb-size--tiny mb-0">{{ t('shared.search-select.ticketsTemporarilyUnavailable') }}</p>
                <p class="eb-size--tiny">{{ t('shared.search-select.firstToKnow') }}</p>
              </div>

              <button eb-button ebType="primary" ebType="secondary" ebSize="small">
                <eb-icon key="bell" /> {{ t('shared.search-select.notifyMe') }}
              </button>
            </ng-container>
            <ng-container *ngSwitchCase="titleEventAvailabilities.SOLD_OUT">
              <p class="h6 eb-title-event-bar__sold-out">{{ t('shared.search-select.soldOut') }}</p>
            </ng-container>
            <ng-container *ngSwitchCase="titleEventAvailabilities.SELL_OPEN_SOON">
              <p class="h6">{{ t('shared.search-select.saleSoon') }}</p>
            </ng-container>
            <ng-container *ngSwitchCase="titleEventAvailabilities.SELL_ENDED">
              <p class="h6">{{ t('shared.search-select.saleEnded') }}</p>
            </ng-container>
            <ng-container *ngSwitchCase="titleEventAvailabilities.CHECK_FREE_SEATS">
              <eb-icon key="loading" />
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-template>

  <ng-template #artistsOptionTemplate let-artist>
    <div class="search-option" (click)="selectArtistOption($event, artist)">
      <div class="search-option__thumbnail">
        <div class="img--circle">
          <img [src]="artist.imageUrl | media" [alt]="artist.artistName" />
        </div>
      </div>
      <div class="ml-3">
        <span class="search-option__title">{{ artist.artistName }}</span>
      </div>
    </div>
  </ng-template>

  <ng-template #placeOptionTemplate let-place>
    <div class="search-option" (click)="selectPlaceOption($event, place)">
      <div class="search-option__thumbnail">
        <img [src]="place.imageUrl | media" [alt]="place.customName" />
      </div>
      <div class="ml-3">
        <span class="search-option__title">{{ place.customName }}</span>
        <span class="search-option__description eb-size--small">
          <span>{{ place.city }}</span>
        </span>
      </div>
    </div>
  </ng-template>
</eb-select>

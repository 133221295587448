<div class="eb-bottom-nav-bar__actions" *transloco="let t">
  <button
    eb-button
    [ebGhost]="true"
    [ngClass]="{ 'eb-bottom-nav-bar--active': isMegaMenuDrawerVisible() }"
    (click)="handleMegaMenuButtonClick()"
  >
    <eb-icon key="grid" />
    {{ t('shared.bottom-nav-bar.categories') }}
  </button>

  <button
    eb-button
    [ebGhost]="true"
    [ngClass]="{ 'eb-bottom-nav-bar--active': isSearchDrawerVisible() }"
    (click)="handleSearchButtonClick()"
  >
    <eb-icon key="search" />
    {{ t('shared.bottom-nav-bar.search') }}
  </button>

  <button eb-button [ebGhost]="true" (click)="showLogin()">
    <eb-icon key="user" />
    {{ t('shared.bottom-nav-bar.account') }}
  </button>
</div>

<eb-category-mega-menu-drawer />

<eb-search-drawer />
